/*const $ = require('jquery');
 global.$ = global.jQuery = $;

 import 'jquery-ui';

 require('jquery-ui/ui/widgets/datepicker');
 require('./jquery.ui.datepicker-fr');
 require('./datatable');
 require('./datatable-utils');
 require('./jquery.mask.min');
 require('./select')*/
const language = window.language ;
$.fn.extend({
    showPopin: function (options) {
        options = options || {};
        // $(this).addClass('ShowPopinProceed')
        const selector = options.selector ?? '.showPopin';
        $('body').on('click',selector,function () {
            $('#loader').show();

            $("#myModal #modal-body").html('');
            $("#myModal #modal-title").html('');
            $("#myModal > .modal-dialog").attr('class', 'modal-dialog modal-dialog-centered');

            let id    = $(this).attr('data-id')
            let url   = $(this).attr('data-href') ? $(this).attr('data-href') : $(this).attr('href');
            let title = $(this).attr('data-title');
            let size  = $(this).attr('data-size') || 'modal-xxl modal-lg';
            let nonce = document.querySelector('script[nonce]').nonce

            $.ajax({
                url: url,
            }).done(function (data) {
                data = data.replace(/<script/g, `<script nonce="${nonce}"`)
                $("#myModal #modal-body").html(data);
                $("#myModal #modal-title").html(title);
                $("#myModal > .modal-dialog").addClass(size);

                if (options.hasOwnProperty('beforeShow')) {
                    options.beforeShow();
                }

                if ($('#documentTable').length) {
                    initDatatable('documentTable');
                }

                $('#myModal').modal('show');
            });

            return false;
        });

        $('#myModal').on('show.bs.modal', function () {
            $('#loader').hide();
        })
    }
});

$.fn.datetimepicker.Constructor.Default = $.extend({}, $.fn.datetimepicker.Constructor.Default, {
        format    : 'DD/MM/YYYY',
        locale    : 'fr',
        useCurrent: false
    });
$(function () {
    // $('input:text, input[type="number"]').attr('autocomplete', 'nope');
    $('input:text').attr('list', 'autocompleteOff');
    $('form').attr('autocomplete', 'off');

    window.initDatatable = function (selector, extraOptions) {
        let targets = [];
        let columns = [];
        $('#' + selector + ' tr:first th').each(function (index) {
            if ($(this).hasClass('unSortable')) {
                targets.push(index);
            }
            if ($(this).data('width')) {
                columns.push({
                                 "width": $(this).data('width')
                             });
            }
            else {
                columns.push(null);
            }
        });
        let columnDefs = [{
            'targets'  : targets, /* column index */
            'orderable': false, /* true or false */
        }];

        if (typeof extraOptions == 'undefined') {
            extraOptions = {};
        }

        let defaultOptions = {
            "deferRender"  : true,
            "paging"       : true,
            "lengthMenu"   : [[10, 25, 50, 100, 250, -1], [10, 25, 50, 100, 250, "Tous"]],
            "dom"          : '<"row"<"col-lg-4"l><"col-lg-4"f><"col-lg-4 text-end"B>>rt<"row"<"col-lg-12 col-md-5"i><"col-lg-12 col-md-7"p>>',
            "buttons"      : {
                dom: {
                    button: {
                        tag: 'button',
                        className: 'btn'
                    }
                },
                buttons:[
                {
                    extend       : 'copy',
                    text         : '<i class="fa fa-copy fa-2x text-primary" data-bs-toggle="tooltip" data-bs-placement="top" title="Copier dans le presse-papier"></i>',
                    init         : function (api, node, config) {
                        $(node).removeClass('btn-secondary');
                    },
                    exportOptions: {
                        columns: ':not(.actions)',
                    },
                },
                {
                    extend       : 'excel',
                    text         : '<i class="fa fa-file-excel fa-2x text-success" data-bs-toggle="tooltip" data-bs-placement="top" title="Export Excel"></i>',
                    init         : function (api, node, config) {
                        $(node).removeClass('btn-secondary');
                    },
                    exportOptions: {
                        columns: ':not(.actions)',
                    },
                    filename     : function () { return getExportFileName() },
                    title        : function () { return getExportTitle() },
                },
                {
                    extend       : 'pdf',
                    orientation  : 'landscape',
                    text         : '<i class="fa fa-file-pdf fa-2x text-danger" data-bs-toggle="tooltip" data-bs-placement="top" title="Export PDF"></i>',
                    init         : function (api, node, config) {
                        $(node).removeClass('btn-secondary');
                    },
                    exportOptions: {
                        columns: ':not(.actions)',
                    },
                    filename     : function () { return getExportFileName() },
                    title        : function () { return getExportTitle() },
                }
            ]},
            "responsive"   : true,
            "bSortCellsTop": true,
            "order"        : [[($('#' + selector + ' th.defaultSort').index() == -1 ? 0 : $('#' + selector + ' th.defaultSort').index()), 'desc']],
            'columnDefs'   : columnDefs,
            "columns"      : columns,
            "pageLength"   : 25,
            language,
            initComplete   : function (settings) {
                $('#overlay').hide();
                $('.dataTables_filter input').css('width', '100%');
                $('#table_length select, #documentTable_length select').removeClass('custom-select custom-select-sm');
                $('#' + selector + ' th.headerFilter').each(function (i) {
                    if ($(this).hasClass('filter')) {
                        var select = $('<select id="' + i + '" class="form-select"><option value="">Tous</option></select>')
                            .appendTo($(this).empty())
                            .on('change', function () {
                                var val = $.fn.dataTable.util.escapeRegex($(this).val());
                                $('#' + selector).DataTable().column(i).search(val ? '^' + val + '$' : '', true, false).draw();
                            });
                        $('#' + selector).DataTable().column(i).data().unique().sort().each(function (d, j) {
                            if (/<[a-z][\s\S]*>/i.test(d)) {
                                d = $.trim($(d).html(d).text());
                            }
                            select.append('<option value="' + d + '">' + d + '</option>')
                        });
                    }
                });

                // Handle Input search
                $('#' + selector + ' th.headerFilter').each(function (i) {
                    if ($(this).hasClass('search')) {
                        $('input', this).on('keyup change', function () {
                            $('#' + selector).DataTable().column(i).search(this.value).draw();
                        });
                    }
                });
                $('[data-bs-toggle="tooltip"]').tooltip();
                $('.showPopin').showPopin({
                    selector: '.showPopin',
                    beforeShow: function () {
                        $('#modal-body form[name="automobile"] input, #modal-body form[name="automobile"] select, #modal-body form[name="automobile"] textarea').attr('disabled', 'disabled');
                        $('#modal-body form[name="sinistre"] input, #modal-body form[name="sinistre"] select, #modal-body form[name="sinistre"] textarea').attr('disabled', 'disabled');
                        $('#modal-body form[name="contrat"] input, #modal-body form[name="contrat"] select, #modal-body form[name="contrat"] textarea').attr('disabled', 'disabled');
                        $('#modal-body form[name="demande"] input, #modal-body form[name="demande"] select, #modal-body form[name="demande"] textarea').attr('disabled', 'disabled');
                        $('#modal-body form[name="statistique"] input, #modal-body form[name="statistique"] select, #modal-body form[name="statistique"] textarea').attr('disabled', 'disabled');
                    }
                });
            }
        };

        $.extend(defaultOptions, extraOptions);
        $('#' + selector).DataTable(defaultOptions);
    }

    $.fn.dataTable.moment('DD/MM/YYYY');

    $.urlParam = function (name) {
        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.search);
        return (results !== null) ? results[1] || 0 : false;
    };

    function getPage() {
        var url  = window.location.href;
        var page = '';

        if (url.indexOf('/demande/') !== -1) {
            page = 'demande';
        }
        if (url.indexOf('/automobile/') !== -1) {
            page = 'automobile';
        }
        if (url.indexOf('/sinistre/') !== -1) {
            page = 'sinistre';
        }
        if (url.indexOf('/contrat/') !== -1) {
            page = 'contrat';
        }
        if (url.indexOf('/prime/') !== -1) {
            page = 'prime';
        }
        if (url.indexOf('/document/') !== -1) {
            page = 'document';
        }
        if (url.indexOf('/entreprise/') !== -1) {
            page = 'entreprise';
        }
        if (url.indexOf('list-users') !== -1 || url.indexOf('/account/') !== -1) {
            page = 'utilisateurs';
        }
        return page;
    }

    function getExportFileName() {
        return moment().format('YMMDHmms') + '_export_liste_' + getPage() + '_zenassist';
    }

    function getExportTitle() {
        return moment().format('YMMDHmms') + ' Export liste ' + getPage() + ' ZenAssist';
    }

    initDatatable('table');

    $('[data-bs-toggle="tooltip"]').tooltip();
    $('[data-bs-toggle="popover"]').popover({
                                             html   : true,
                                             content: function () {
                                                 return $('#' + $(this).data('target')).html();
                                             }
                                         });
    $('[data-bs-toggle="popover"]').on('click', function (e) {
        $('[data-bs-toggle="popover"]').not(this).popover('hide');
    });


    $(document).ready(function(){

        $('div[data-toggle="datetimepicker"]').parent().datetimepicker({
            format    : 'DD/MM/YYYY',
            locale    : 'fr',
            useCurrent: false,
            allowInputToggle: true,
        });
    });

    if (window.location.hash.indexOf('#popin_') == 0) {
        var path = window.location.hash.split('_');
        $("button.showPopin[data-id='" + path[1] + "'").trigger('click');
    }

    let $autocompleteSelect2Element = $(".code_client");
    let autocompleteSelect2CodeClientSettings = {
        minimumInputLength: 3,
        placeholder: 'N° ou Nom',
        theme: "bootstrap-5",
        language: "fr",
        ajax: {
            url: $autocompleteSelect2Element.data('url'),
            data: function (params) {
                let query = {
                    term: params.term,
                }
                return query;
            },
            processResults: function (data) {
                return {
                    results: data
                };
            },
            delay    : 300,
        },
        templateSelection: formatRepoSelection
    };
    // Initialisation de l'auto-completion recherche code client
    $autocompleteSelect2Element.select2(autocompleteSelect2CodeClientSettings);

    function formatRepoSelection (clients) {
        return clients.text;
    }

    $('#myModal').on('show.bs.modal', function () {
        $('#loader').hide();
    });

    /* NEW FILTER*/
    $('.filter-' + $('#selectFilter2').val() + ' input[name="selectFilter"]').val($('#selectFilter2').val());
    $('#selectFilter2').on('change', function () {
        $('#filter div.row').hide();
        $('#filter .filter-' + $(this).val()).toggle();
        $(".code_client").select2(autocompleteSelect2CodeClientSettings);
        $('#filter .filter-' + $(this).val()).find('input:hidden[name=selectFilter]').val($(this).val());
    });

    $('#filter form').submit(function () {
        if ($(this).parent().hasClass('filter-demande') && $('input[name="demande"]').val() == "") {
            toastr.warning("Veuillez renseigner un numéro de demande");
            return false;
        }
        if ($(this).parent().hasClass('filter-sinistre') && $('input[name="sinistre"]').val() == "") {
            toastr.warning("Veuillez renseigner un numéro de sinistre");
            return false;
        }
        if ($(this).parent().hasClass('filter-immatriculation') && $('input[name="immatriculation"]').val() == "") {
            toastr.warning("Veuillez renseigner une immatriculation");
            return false;
        }
        if ($(this).parent().hasClass('filter-contrat') && $('input[name="contrat"]').val() == "") {
            toastr.warning("Veuillez renseigner un numéro de contrat");
            return false;
        }
        if ($(this).parent().hasClass('filter-client') && $('input[name="code_client"]').val() == "") {
            toastr.warning("Veuillez renseigner un numéro ou nom de client");
            return false;
        }
    });

    document.addEventListener('invalid', function (e) {
        $(e.target).addClass("invalid");
        $('html, body').animate({scrollTop: $($(".invalid")[0]).offset().top - 200}, 0);
    }, true);

    document.addEventListener('change', function (e) {
        $(e.target).removeClass("invalid")
    }, true);

    $(document).ajaxError(function (event, request, settings) {
        if (request.status === 500 || request.status === 502 ) {
            toastr.error('Erreur technique');
        }else if (request.status === 400  || request.status === 404) {
            toastr.info(request.responseJSON);
        }else if (request.status === 401 ) {
            if(request.responseJSON.redirectPath !== 'undefined'){
                window.location = request.responseJSON.redirectPath;
            }
            toastr.error('Erreur : accès refusé.');
        }
        else {
            let message = 'Une erreur s\'est produite';
            if(typeof(request.responseJSON) === "string") {
                message = request.responseJSON.replace(/error:/i, '')
            }
            toastr.error('Erreur ' + request.status + ' : ' + message);
        }
        $('#loader').hide();
    });

    $('#myModal').on('hide.bs.modal', function () {
        return $('#document_documentFile_file').val() && confirm("Vous n'avez pas ajouté votre fichier. Voulez-vous fermer la fenêtre ?");
    });

    $('#table').on('click', '.openModalForRefus', function () {
        $(this).closest('div.group').find('.showDemande').click();
        $('#myModal').one('shown.bs.modal', function () {
            $('#formReason').removeClass('d-none');
            $('#reason').focus();
        });
    });

    $('select[multiple]').select2({
        theme: "bootstrap-5",
        width: '100%',
        // placeholder: $( this ).data( 'placeholder' ),
        closeOnSelect: false,
        // dropdownCssClass: $(this).parent(),// Required for dropdown styling
    })
    // Trick pour donner le focus à select2 lorsque l'on clique sur le champ
    // https://stackoverflow.com/questions/68030101/why-is-jquery-select2-autofocus-not-working-and-how-do-i-fix-it
    $(document).on('select2:open', function(e) {
        document.querySelector(`[aria-controls="select2-${e.target.id}-results"]`).focus();
    });

    document.querySelectorAll('input[type="date"]').forEach(function(input) {
        input.addEventListener('focus', function() {
            this.showPicker();
        });
    });
});

